export default function SpringLogo({ className = '' }) {
  return (
    <a href='https://spring.io/projects/spring-boot' target='_blank' rel='noreferrer' title='Spring Boot'>
      <svg className={className} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 107.6 107.7" style={{enablBackground: 'new 0 0 107.6 107.7'}} xmlSpace="preserve">
      <path fill='#FFFFFF' d="M24.4,93.5c-0.9,1.1-2.2,1.7-3.6,1.7c-2.5,0-4.6-2.1-4.6-4.6s2.1-4.6,4.6-4.6c1.1,0,2.1,0.4,2.9,1  C25.7,88.6,26,91.5,24.4,93.5L24.4,93.5z"/>
      <path fill='#FFFFFF' d="M97.6,77.3c-13.3,17.8-41.8,11.8-60,12.6c0,0-3.2,0.2-6.5,0.7c0,0,1.2-0.5,2.8-1.1c12.8-4.5,18.9-5.3,26.6-9.3  c14.6-7.5,29.1-23.8,32.1-40.7C87,55.8,70.1,69.9,54.7,75.6c-10.6,3.9-29.6,7.7-29.6,7.7l-0.8-0.4c-13-6.3-13.4-34.4,10.2-43.5  c10.3-4,20.2-1.8,31.4-4.5C78,32,91.7,23.1,97.3,11.4C103.6,30,111.2,59.3,97.6,77.3L97.6,77.3z"/>
      <path fill='#77BC1F' d="M97.9,5.6c-1.5,3.6-3.4,7-5.7,10.2C82,5.7,68.3,0,54,0C24.4,0,0,24.4,0,54c0,14.8,6.1,29,16.8,39.2l2,1.8  c9.7,8.2,22,12.7,34.8,12.7c28.1,0,51.8-22.1,53.8-50.2C108.8,43.7,104.7,26.3,97.9,5.6L97.9,5.6z M24.4,93.5  c-0.9,1.1-2.2,1.7-3.6,1.7c-2.5,0-4.6-2.1-4.6-4.6s2.1-4.6,4.6-4.6c1.1,0,2.1,0.4,2.9,1C25.7,88.6,26,91.5,24.4,93.5L24.4,93.5z   M97.6,77.3c-13.3,17.8-41.8,11.8-60,12.6c0,0-3.2,0.2-6.5,0.7c0,0,1.2-0.5,2.8-1.1c12.8-4.5,18.9-5.3,26.6-9.3  c14.6-7.5,29.1-23.8,32.1-40.7C87,55.8,70.1,69.9,54.7,75.6c-10.6,3.9-29.6,7.7-29.6,7.7l-0.8-0.4c-13-6.3-13.4-34.4,10.2-43.5  c10.3-4,20.2-1.8,31.4-4.5C78,32,91.7,23.1,97.3,11.4C103.6,30,111.2,59.3,97.6,77.3L97.6,77.3z"/>
      </svg>
    </a>
  );
}