export default function FlutterLogo({ className = '' }) {
  return (
    <a href='https://flutter.dev/' target='_blank' rel='noreferrer' title='Flutter'>
      <svg xmlns="http://www.w3.org/2000/svg" width="156" height="35" viewBox="0 0 156 44" fill="none">
      <g clipPath="url(#clip0)">
      <path d="M54.9348 9.12305H69.5877V11.9923H57.9155V20.6559H68.4455V23.4973H57.9155V34.473H54.9348V9.12305Z" fill="white"/>
      <path d="M73.1255 9.12305H76.1341V34.4452H73.1255V9.12305Z" fill="white"/>
      <path d="M82.1793 33.1083C81.0372 31.8269 80.4521 30.044 80.4521 27.7598V16.394H83.4607V27.2862C83.4607 29.0133 83.8507 30.2669 84.6307 31.0748C85.4107 31.8826 86.4693 32.2726 87.7786 32.2726C88.7814 32.2726 89.7007 31.994 90.4807 31.4648C91.2607 30.9355 91.8736 30.2112 92.3193 29.3476C92.765 28.484 92.96 27.5648 92.96 26.5898V16.394H95.9964V34.4455H93.1271V31.8269H92.9879C92.4864 32.7183 91.7064 33.4705 90.62 34.0833C89.5336 34.6962 88.3914 35.0026 87.1657 35.0026C84.965 35.0305 83.3214 34.3898 82.1793 33.1083Z" fill="white"/>
      <path d="M105.523 34.668C104.911 34.4173 104.381 34.1109 103.936 33.693C103.462 33.2473 103.1 32.718 102.877 32.1052C102.654 31.4923 102.515 30.768 102.515 29.9045V19.1238H99.3669V16.3938H102.515V11.2959H105.523V16.3938H109.925V19.1238H105.551V29.1802C105.551 30.183 105.746 30.9352 106.108 31.4088C106.554 31.938 107.195 32.2166 108.058 32.2166C108.755 32.2166 109.396 32.0216 110.036 31.6038V34.5288C109.674 34.6959 109.312 34.8073 108.95 34.8909C108.588 34.9745 108.114 35.0023 107.557 35.0023C106.833 35.0302 106.136 34.8909 105.523 34.668Z" fill="white"/>
      <path d="M118.282 34.668C117.669 34.4173 117.14 34.1109 116.694 33.693C116.22 33.2473 115.858 32.718 115.635 32.1052C115.413 31.4923 115.273 30.768 115.273 29.9045V19.1238H112.153V16.3938H115.301V11.2959H118.31V16.3938H122.683V19.1238H118.31V29.1802C118.31 30.183 118.505 30.9352 118.867 31.4088C119.313 31.938 119.953 32.2166 120.817 32.2166C121.513 32.2166 122.154 32.0216 122.795 31.6038V34.5288C122.433 34.6959 122.07 34.8073 121.708 34.8909C121.346 34.9745 120.873 35.0023 120.315 35.0023C119.591 35.0302 118.923 34.8909 118.282 34.668Z" fill="white"/>
      <path d="M128.952 33.7769C127.587 32.9412 126.528 31.8269 125.748 30.3505C124.968 28.9019 124.606 27.2584 124.606 25.4477C124.606 23.6927 124.968 22.1048 125.692 20.6284C126.417 19.1798 127.42 18.0098 128.757 17.1184C130.066 16.2548 131.598 15.8091 133.353 15.8091C135.108 15.8091 136.668 16.1991 137.95 17.0069C139.259 17.7869 140.234 18.9012 140.93 20.2662C141.627 21.6591 141.961 23.2469 141.961 25.0298C141.961 25.3919 141.933 25.6984 141.85 25.9491H127.587C127.67 27.3141 127.977 28.4841 128.59 29.4034C129.175 30.3505 129.927 31.0469 130.846 31.5205C131.765 31.9941 132.712 32.2169 133.687 32.2169C136 32.2169 137.782 31.1305 139.036 28.9577L141.571 30.1834C140.791 31.6319 139.732 32.8019 138.395 33.6377C137.058 34.4734 135.442 34.9191 133.576 34.9191C131.877 35.0305 130.317 34.6127 128.952 33.7769ZM138.785 23.5255C138.73 22.7734 138.535 22.0212 138.145 21.2691C137.755 20.5169 137.17 19.8762 136.362 19.3469C135.554 18.8177 134.523 18.5669 133.297 18.5669C131.877 18.5669 130.679 19.0127 129.704 19.9319C128.729 20.8512 128.088 22.0491 127.782 23.5255H138.785Z" fill="white"/>
      <path d="M145.471 16.4219H148.34V19.3191H148.479C148.841 18.3162 149.51 17.5083 150.541 16.8398C151.544 16.1712 152.602 15.8369 153.716 15.8369C154.552 15.8369 155.249 15.9762 155.834 16.2269V19.4583C155.081 19.0683 154.218 18.9012 153.299 18.9012C152.435 18.9012 151.627 19.1519 150.903 19.6533C150.179 20.1548 149.594 20.8233 149.148 21.6591C148.702 22.4948 148.507 23.4141 148.507 24.3612V34.4733H145.499V16.4219H145.471Z" fill="white"/>
      <rect x="9.89282" y="18.0063" width="31.3277" height="24.4208" fill="url(#pattern0)"/>
      <path d="M37.0631 20.0376H23.7056L12.0195 31.7265L18.6969 38.4038L37.0631 20.0376Z" fill="#54C5F8"/>
      <path d="M8.68014 28.3864L2 21.7063L23.7063 0H37.0638L8.68014 28.3864Z" fill="#54C5F8"/>
      <path d="M18.6975 38.4039L23.7062 43.4126H37.0637L25.3749 31.7266L18.6975 38.4039Z" fill="#01579B"/>
      <path d="M18.6975 38.4039L28.6035 34.9747L25.3749 31.7266L18.6975 38.4039Z" fill="url(#paint0_linear)"/>
      <path d="M18.6966 25.0456L12.019 31.7231L18.6966 38.4007L25.3741 31.7231L18.6966 25.0456Z" fill="#29B6F6"/>
      <path d="M37.0638 20.0376L25.3777 31.7237L37.0638 43.4126H23.7063L18.6976 38.4039L12.0174 31.7237L23.7063 20.0376H37.0638ZM23.7063 0L2 21.7063L8.68014 28.3864L37.0638 0H23.7063Z" fill="url(#paint1_radial)"/>
      </g>
      <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
      </pattern>
      <linearGradient id="paint0_linear" x1="20.6447" y1="39.6934" x2="25.6192" y2="34.7189" gradientUnits="userSpaceOnUse">
      <stop stopColor="#1A237E" stopOpacity="0.4"/>
      <stop offset="1" stopColor="#1A237E" stopOpacity="0"/>
      </linearGradient>
      <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.98664 2.95995) scale(53.1128)">
      <stop stopColor="white" stopOpacity="0.1"/>
      <stop offset="1" stopColor="white" stopOpacity="0"/>
      </radialGradient>
      <clipPath id="clip0">
      <rect width="156" height="43.641" fill="white"/>
      </clipPath>
      </defs>
      </svg>
    </a>
  );
}